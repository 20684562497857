import { z } from 'zod';

const clientEnvSchema = z.object({
  VITE_APP_ENVIRONMENT: z.enum(['dev', 'staging', 'prod']),
  VITE_APP_STRIPE_CLIENT_KEY: z.string(),
  VITE_APP_MSW_ENABLED: z.coerce.boolean().optional(),
  MODE: z.enum(['development', 'test', 'production']),
  VITE_APP_STRIPE_CONNECTED_ACCOUNT_ID: z.string(),
});

const clientEnv = clientEnvSchema.parse(import.meta.env);

export default clientEnv;
